<template>
  <v-container fluid class="down-top-padding">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <MenuFloating></MenuFloating>
    <v-row>
      <v-col cols="12" sm="12" lg="3" class="pa-3">
        <PageTitle icon="mdi-home" :main="razaoSocial" :sub="datahoraChart" />
      </v-col>

      <!-- item-value="cod_filial" -->
      <v-col cols="12" sm="12" lg="6" class="pa-3">
        <v-select
          v-model="empresaSelecionadaFilial.cod_filial"
          :items="empresasDashboard"
          dense
          item-text="empresa"
          item-value="cod_filial"
          label="Selecione a empresa"
          hide-details="auto"
          v-on:change="changeEmpresa"
          outlined
          persistent-hint
        ></v-select>
      </v-col>

      <v-col cols="12" sm="12" lg="3" class="pa-3">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dataPesquisa"
              dense
              outlined
              readonly
              persistent-hint
              append-icon="mdi-calendar"
              label="Selecione período mês-ano"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateBr"
            type="month"
            locale="pt-br"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="dataBr"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-btn
      class="my-1"
      fab
      small
      dark
      color="blue darken-2"
      absolute
      botton
      right
      @click="getDashboard"
      ><v-icon> mdi-sync </v-icon></v-btn
    >
    <!-- <v-btn
      class="my-5"
      fab
      small
      dark
      color="blue darken-2"
      absolute
      botton
      right
      @click="getTeste"
      ><v-icon> mdi-plus </v-icon></v-btn
    > -->

    <div class="stats">
      <Stat
        title="Venda Bruta - Mês"
        :value="Number(dashboard.venda_mes)"
        icon="mdi-chart-line"
        color="#64B5F6"
      />
      <Stat
        title="Devolução - Mês "
        :value="Number(dashboard.devolucao_mes)"
        icon="mdi-chart-line-stacked"
        color="#FFF176"
      />
      <Stat
        title="Venda Líquida - Mês"
        :value="Number(dashboard.venda_mes - dashboard.devolucao_mes)"
        icon="mdi-chart-line"
        color="#4DD0E1"
      />
      <Stat
        title="Lucro Bruto - Mês "
        :value="Number(dashboard.lucro_mes)"
        icon="mdi-chart-line-stacked"
        color="#3bc480"
      />
    </div>
    <div class="stats">
      <Stat
        title="Venda Bruta - Dia"
        :value="Number(dashboard.venda_dia)"
        icon="mdi-chart-bar"
        color="#64B5F6"
      />
      <Stat
        title="Devolução - Dia "
        :value="Number(dashboard.devolucao_dia)"
        icon="mdi-chart-line-stacked"
        color="#FFF176"
      />
      <Stat
        title="Venda Líquida - Dia"
        :value="Number(dashboard.venda_dia - dashboard.devolucao_dia)"
        icon="mdi-chart-bar"
        color="#4DD0E1"
      />
      <Stat
        title="Lucro Bruto - Dia"
        :value="Number(dashboard.lucro_dia)"
        icon="mdi-chart-bar-stacked"
        color="#3bc480"
      />
    </div>

    <v-row>
      <!-- <v-col cols="12" lg="8"> -->
      <v-col>
        <v-card>
          <v-card-text class="pa-3">
            <div class="d-sm-flex align-center">
              <div>
                <h3
                  class="title blue-grey--text text--darken-2 font-weight-regular"
                >
                  Venda por Seção
                </h3>
              </div>
            </div>
            <div class="mt-9">
              <apexchart
                id="chart1"
                ref="chart1"
                width="100%"
                height="350"
                type="bar"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="12" lg="8"> -->
      <v-col>
        <v-card>
          <v-card-text class="pa-3">
            <div class="d-sm-flex align-center">
              <div>
                <h3
                  class="title blue-grey--text text--darken-2 font-weight-regular"
                >
                  Venda por Dia
                </h3>
              </div>
            </div>
            <div class="mt-9">
              <apexchart
                ref="chart2"
                width="100%"
                height="450"
                type="bar"
                :options="chartOptions2"
                :series="series2"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from "@/layouts/PageTitle.vue";
import MenuFloating from "@/layouts/menuFloating/MenuFloating.vue";
import Stat from "@/components/Stat.vue";
import axios from "axios";
import { baseApiUrl, showError, showSucess } from "@/global";
import { mapState } from "vuex";
import * as XLSX from "xlsx";
export default {
  name: "Home",
  components: { PageTitle, Stat, MenuFloating },
  data: function() {
    return {
      dateBr: new Date().toISOString().substr(0, 7),
      dataPesquisa: "",
      menu: false,

      modal: false,
      overlay: false,
      venda_dia: 0,
      lucro_dia: 0,
      cargaInicial: false,
      stat: {},
      dashboard: {},
      newSerie: [],
      datahoraChart: null,
      newCategories: [],
      newSerie2: [],
      newCategories2: [],
      heightChart1: 150,
      heightChart2: 150,
      mostrarChart1: true,
      razaoSocial: "Dashboard",
      empresasDashboard: [],
      empresaSelecionadaFilial: {},

      chartOptions: {
        width: "100%",
        height: "380",

        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return (
              "R$ " +
              val
                .toFixed(2)
                .replace(".", ",")
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
          },
          style: {
            colors: ["#333"],
          },
          offsetX: 30,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: ["#64B5F6", "#FFF176", "#4DD0E1", "#3bc480"],
        noData: {
          text: "Carregando...",
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "top",
        },
        responsive: [
          {
            breakpoint: 1000,

            options: {
              height: 300,
              // height: 200,
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
            },
          },
        ],
      },
      series: [
        {
          name: "series-1",
          data: [],
          // show: false,
        },
      ],
      chartOptions2: {
        width: "100%",
        height: "380",
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return (
              "R$ " +
              val
                .toFixed(2)
                .replace(".", ",")
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
          },
          style: {
            colors: ["#333"],
          },
          offsetX: 30,
        },
        colors: ["#64B5F6", "#FFF176", "#4DD0E1", "#3bc480"],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        noData: {
          text: "Carregando...",
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "top",
        },
        responsive: [
          {
            breakpoint: 1000,

            options: {
              height: 300,
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
            },
          },
        ],
      },
      series2: [
        {
          name: "series-2",
          data: [],
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    // getStats() {
    //   axios.get(`${baseApiUrl}/stats`).then((res) => (this.stat = res.data));
    // },

    dataBr() {
      this.dataPesquisa =
        this.dateBr.substr(5, 2) + "-" + this.dateBr.substr(0, 4);
      this.modal = false;
      this.getSelecionaEmpresas();
    },

    getSelecionaEmpresas() {
      this.overlay = true;

      let periodo =
        this.dataPesquisa.substring(0, 2) +
        "/" +
        this.dataPesquisa.substring(3, 7);

      // let periodo = new Date(Date().valueOf());
      // const mm = String(periodo.getMonth() + 1).padStart(2, "0");
      // const yyyy = periodo.getFullYear();

      // periodo = mm + "/" + yyyy;

      axios
        .get(
          `${baseApiUrl}/dashboard_consolidado?FILIAL=1&PERIODO=` + periodo,
          {
            timeout: 10000,
          }
        )
        .then((res) => {
          this.empresasDashboard = res.data;
          this.cargaInicial = true;
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          showError("Erro na sincronia de dados " + error.response.data);
        });
    },
    changeEmpresa(a) {
      let empresa2 = {};

      this.empresasDashboard.forEach(function(object) {
        if (object.cod_filial == a) {
          empresa2.cod_filial = object.cod_filial;
          empresa2.empresa = object.empresa;
        }
      });

      this.empresaSelecionadaFilial = empresa2;
      this.getDashboard();
    },

    getTeste() {
      var horaInicio = new Date();

      console.log(
        " tempo1 :" +
          horaInicio.getMinutes() +
          ":" +
          horaInicio.getSeconds() +
          ":" +
          horaInicio.getMilliseconds()
      );

      axios
        .get(`${baseApiUrl}/teste`, {
          timeout: 10000,
        })
        .then((res) => {
          console.log(res);
          var horaInicio2 = new Date();
          console.log(
            " tempo2 :" +
              horaInicio2.getMinutes() +
              ":" +
              horaInicio2.getSeconds() +
              ":" +
              horaInicio2.getMilliseconds()
          );
        })
        .catch((error) => {
          this.overlay = false;
          showError("Erro na sincronia de dados " + error);
        });
    },

    async getDashboard() {
      this.overlay = true;

      // let periodo = new Date(Date().valueOf());
      // const mm = String(periodo.getMonth() + 1).padStart(2, "0");
      // const yyyy = periodo.getFullYear();

      let periodo =
        this.dataPesquisa.substring(0, 2) +
        "/" +
        this.dataPesquisa.substring(3, 7);

      let periodoDia = new Date(Date().valueOf());
      var horaInicio = new Date();

      var iconImage = new Image();

      iconImage.src = require("@/assets/microsoft-excel-custom.png");

      console.log(
        " tempo1 :" +
          horaInicio.getMinutes() +
          ":" +
          horaInicio.getSeconds() +
          ":" +
          horaInicio.getMilliseconds()
      );

      await axios
        .get(
          `${baseApiUrl}/dashboard_v2?FILIAL=` +
            this.empresaSelecionadaFilial.cod_filial +
            `&PERIODO=` +
            periodo,
          {
            timeout: 10000,
          }
        )
        .then((res) => {
          var horaInicio2 = new Date();
          console.log(
            " tempo2 :" +
              horaInicio2.getMinutes() +
              ":" +
              horaInicio2.getSeconds() +
              ":" +
              horaInicio2.getMilliseconds()
          );
          this.overlay = false;
          this.dashboard = res.data;
          this.heightChart1 = 150;
          this.heightChart2 = 150;

          let dt =
            this.dashboard.created_at.substring(3, 5) +
            "-" +
            this.dashboard.created_at.substring(0, 2) +
            "-" +
            this.dashboard.created_at.substring(6, 10);

          let novaFuncaoExcelSecao = this.exportToExcelSecao;

          let novaFuncaoExcelSecaoDia = this.exportToExcelSecaoDia;

          let periodoDashboard = new Date(dt);
          if (
            periodoDia.getDate() == periodoDashboard.getDate() &&
            periodoDia.getMonth() == periodoDashboard.getMonth() &&
            periodoDia.getFullYear() == periodoDashboard.getFullYear()
          ) {
            this.venda_dia = Number(this.dashboard.venda_dia);
            this.lucro_dia = Number(this.dashboard.lucro_dia);
          }

          this.datahoraChart = "Atualizado: " + this.dashboard.created_at;
          this.razaoSocial = "Dashboard";

          this.newCategories = this.dashboard.dashboard_secao.map(
            (v) => v.secao
          );
          // for (let index = 0; index < this.newCategories.length; index++) {
          //   let element = this.newCategories[index];

          //   if (element.length == 0) {
          //     element = "SemCategoria";
          //   }
          //   // const newArrayElement = element
          //   //   .split(" ")
          //   //   .map((x) => this.formaterCategories(x));

          //   // this.newCategories[index] = newArrayElement.filter((n) => n);

          //   this.newCategories = this.dashboard.dashboard_dia.map((v) => v.data);
          // }

          if (this.newCategories.length > 0) {
            this.heightChart1 =
              this.heightChart1 + this.newCategories.length * 60;
          }

          this.chartOptions = {
            chart: {
              height: this.heightChart1,
              toolbar: {
                show: true,
                offsetX: 30,
                tools: {
                  download: true,
                  customIcons: [
                    {
                      icon: "<img src='microsoft-excel-custom.png' width='20'>",
                      index: 0,
                      title: "Exportação para Excel",
                      class: "custom-icon",
                      click: function() {
                        //// chamar a função de exportar para excel
                        novaFuncaoExcelSecao();
                      },
                    },
                  ],
                },
              },
            },

            xaxis: {
              categories: this.newCategories,
              type: "categories",
              tickPlacement: "on",
              labels: {
                rotate: -45,
                rotateAlways: true,
              },
            },
            yaxis: {
              tickPlacement: "on",
              labels: {
                align: "center",
                maxWidth: 130,
                offsetX: 0,
              },
            },
            grid: {
              padding: {
                left: 10,
                bottom: 40,
              },
            },

            // grid: {
            //   padding: {
            //     left: -5,
            //     bottom: 40,
            //   },
            // },
          };

          // this.$refs.chart1.toggleSeries('Venda Bruta');
          // this.$refs.chart1.toggleSeries('Devolução');

          this.$refs.chart1.updateSeries([
            (this.series[0] = {
              name: "Venda Bruta",
              data: this.dashboard.dashboard_secao.map((v) =>
                Number(v.valor_venda)
              ),
            }),
            (this.series[1] = {
              name: "Devolução",
              data: this.dashboard.dashboard_secao.map((v) =>
                Number(v.valor_devolucao)
              ),
            }),
            (this.series[2] = {
              name: "Venda Líquida",
              data: this.dashboard.dashboard_secao.map((v) =>
                Number(v.valor_venda - v.valor_devolucao)
              ),
            }),
            (this.series[3] = {
              name: "Lucro",
              data: this.dashboard.dashboard_secao.map((v) =>
                Number(v.valor_lucro)
              ),
            }),
          ]);

          this.$refs.chart1.toggleSeries("Venda Bruta");
          this.$refs.chart1.toggleSeries("Devolução");

          // this.$refs.chart1.hideSeries('Venda Bruta');
          // this.$refs.chart1.hideSeries('Devolução');
          // this.$refs.chart1.toggleSeries('Venda Bruta');
          // this.$refs.chart1.toggleSeries('Devolução');

          this.newCategories2 = this.dashboard.dashboard_dia.map((v) => v.data);
          // for (let index = 0; index < this.newCategories.length; index++) {
          //   const element = this.newCategories[index];
          //   console.log(element);
          //   const newArrayElement = element.split(' ').map((x) =>  this.formaterCategories(x)     );
          //   console.log(newArrayElement);
          //   this.newCategories[index] = newArrayElement.filter(n => n);
          // }

          if (this.newCategories2.length > 0) {
            this.heightChart2 =
              this.heightChart2 + this.newCategories2.length * 60;
            // this.heightChart2 = 800;
          }

          this.chartOptions2 = {
            chart: {
              height: this.heightChart2,
              toolbar: {
                show: true,
                offsetX: 30,
                tools: {
                  download: true,
                  customIcons: [
                    {
                      icon: "<img src='microsoft-excel-custom.png' width='20'>",
                      index: 0,
                      title: "Exportação para Excel",
                      class: "custom-icon",
                      click: function() {
                        //// chamar a função de exportar para excel
                        novaFuncaoExcelSecaoDia();
                      },
                    },
                  ],
                },
              },
            },
            xaxis: {
              categories: this.newCategories2,
              type: "categories",
              tickPlacement: "on",
              labels: {
                rotate: -45,
                rotateAlways: true,
              },
            },
            yaxis: {
              tickPlacement: "on",
              labels: {
                align: "center",
                maxWidth: 130,
                offsetX: 0,
              },
            },
            grid: {
              padding: {
                left: 10,
                bottom: 40,
              },
            },
          };

          // this.$refs.chart2.updateChart(
          //   this.height = 1000
          // )

          this.$refs.chart2.updateSeries([
            (this.series2[0] = {
              name: "Venda Bruta",
              data: this.dashboard.dashboard_dia.map((v) =>
                Number(v.valor_venda)
              ),
            }),
            (this.series2[1] = {
              name: "Devolução",
              data: this.dashboard.dashboard_dia.map((v) =>
                Number(v.valor_devolucao)
              ),
            }),
            (this.series2[2] = {
              name: "Venda Líquida",
              data: this.dashboard.dashboard_dia.map((v) =>
                Number(v.valor_venda - v.valor_devolucao)
              ),
            }),
            (this.series2[3] = {
              name: "Lucro",
              data: this.dashboard.dashboard_dia.map((v) =>
                Number(v.valor_lucro)
              ),
            }),
          ]);

          this.$refs.chart2.toggleSeries("Venda Bruta");
          this.$refs.chart2.toggleSeries("Devolução");
          if (this.cargaInicial) {
            showSucess("Sincronizado com sucesso !");
          }
          this.cargaInicial = true;
        })
        .catch((error) => {
          this.overlay = false;
          if (
            error.response.data.codigo != null &&
            error.response.data.codigo == -1
          ) {
            showError(error.response.data.msg);
          } else {
            showError("Erro na sincronia de dados " + error);
          }
        });
    },

    formaterCategories(value) {
      let result = "";
      if (value.length > 0) {
        result = value;
      } else {
        result = " ";
      }
      return result;
    },

    exportToExcelSecao() {
      const data = this.dashboard.dashboard_secao.map((v) => {
        return [
          v.secao,
          +v.valor_venda,
          +v.valor_devolucao,
          v.valor_venda - v.valor_devolucao,
          +v.valor_lucro,
        ];
      });

      data.unshift([
        "Seção",
        "Venda Bruta",
        "Devolução",
        "Venda Líquida",
        "Lucro",
      ]);

      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, "Secao_vendas.xlsx");
      // let ws = XLSX.utils.json_to_sheet(this.dashboard.dashboard_secao);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // return XLSX.writeFile(wb, "export.xlsx");
    },

    exportToExcelSecaoDia() {
      const data = this.dashboard.dashboard_dia.map((v) => {
        return [
          v.data,
          +v.valor_venda,
          +v.valor_devolucao,
          v.valor_venda - v.valor_devolucao,
          +v.valor_lucro,
        ];
      });

      data.unshift([
        "Data",
        "Venda Bruta",
        "Devolução",
        "Venda Líquida",
        "Lucro",
      ]);

      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, "Dia_vendas.xlsx");
      // let ws = XLSX.utils.json_to_sheet(this.dashboard.dashboard_secao);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // return XLSX.writeFile(wb, "export.xlsx");
    },

    updateChart() {
      // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.

      // In the same way, update the series option
      this.series = [
        {
          data: [10, 20, 30, 40, 40],
        },
      ];
      // this.chartOptions.xaxis = {

      //   categories :  ['CONTRATOS DE SUPORTE ', 'SISTEMAS', 'EQUIPAMENTOS', 'SUPRIMENTOS', 'PEÇAS E SERVIÇOS DE MANUTENCAO']
      // };
      // const max = 90;
      // const min = 20;
      // const newData = this.series[0].data.map(() => {
      //   return Math.floor(Math.random() * (max - min + 1)) + min;
      // });
      //  const colors = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"];
      // // // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.
      // this.chartOptions = {
      //   colors: [colors[Math.floor(Math.random() * colors.length)]],
      // };
      // In the same way, update the series option
      //   this.series = [
      //     {
      //       data: ,
      //     },
      //   ];
    },
  },
  async mounted() {
    // this.getStats();
    await this.dataBr();
    if (this.user.cnpj) {
      this.empresaSelecionadaFilial.cod_filial = 0;
      this.getDashboard();

      this.getSelecionaEmpresas();
    }

    this.$store.state.isMenuVisibleMobile = true;
  },
};
</script>

<style>
.x-ctn {
  height: 100%;
}
.stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: white;
}
</style>
