<template>
  <!-- <v-speed-dial
    v-if="$vuetify.breakpoint.mobile"
    elevation="2"
    v-model="fab"
    
    absolute
    top
    right
    
  >
    <template v-slot:activator>
      <v-btn v-model="fab" color="blue darken-2" small dark fab  >
        <v-icon v-if="fab"> mdi-close </v-icon>
        <v-icon v-else> mdi-menu </v-icon>        
      </v-btn>
    </template>
  </v-speed-dial> -->
  <div class="my-0" >
    <v-btn
      class="my-0"
      fab
      small
      dark
      color="blue darken-2"
      absolute
      botton
      right
      @click="setMenuMobile"
    >
      <v-icon v-if="!$store.state.isMenuVisibleMobile"> mdi-close </v-icon>
      <v-icon v-else> mdi-menu </v-icon>
    </v-btn>
  </div>
</template>

<script>
// import {  mapMutations } from "vuex";
export default {
  name: "MenuFloating",

  methods: {
    setMenuMobile() {
      if (this.$store.state.isMenuVisibleMobile) {
        this.$store.state.isMenuVisibleMobile = false;
      } else {
        this.$store.state.isMenuVisibleMobile = true;
      }
      this.fabEnabled=this.$store.state.isMenuVisibleMobile;
      
    },


  },
 

 
  
    
  //   fab: function () {
  //     this.setMenuMobile();
  //     console.log("passou");
  //   },
  
};
</script>

<style>
</style>