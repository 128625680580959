<template>
    <v-card elevation="5"
        outlined
        shaped
        tile class="stat">
        <!-- <div class="d-flex flex-no-wrap justify-space-between"> -->
           <v-icon class="stat-icon" :style="style" style="font-size: 3rem" >{{ icon }}</v-icon>  
           <div  class="stat-info"
               
               >
              <div
                 
                  class="pa-2"
                
                v-text="title"
                style="font-size: 1.2rem"
              >  
              </div>
              <div
                  class="pa-2"
                  
                v-text="formataValor(value)"
                style="font-size: 1.7rem"        
              >

              </div>

           </div>


           <!-- <v-coluns align-end >
                          <v-card-title
                  class="text-h5"
                  v-text="title"
                ></v-card-title>
            <v-card-title
                  class="text-h5"
                  v-text="value"
                ></v-card-title>    

           </v-coluns>   -->




    </v-card>
  
</template>

<script>
export default {
  name: "Stat",
  props: ["title", "value", "icon", "color"],
  computed: {
    style() {
      return "color: " + (this.color || "#000");
    },
  },
  methods: {
    formataValor(value) {
      return "R$ " + value
                .toFixed(2)
                .replace(".", ",")
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".") 
    }


  }
};
</script>
<style>
.stat {
  flex: 1;
  display: flex;
  border-radius: 8px;
  margin: 10px;
  /* margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 10px; */
  background-color: #fff;
  padding: 10px;
}

    .stat-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        
    }

    .stat-title {
        font-size: 1.2rem;
        
    }

    .stat-value {
        font-size: 3rem;
        
    }


</style>